import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocalizedSentenceDict } from "../../../../../hooks/useSentenceDict";
import { ALLoading, ALButton } from "../../../../ALComponents";

import "./EditCustomerDetailForm/EditCustomerDetailForm.scss";

const editCustomerDetailFormFieldType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func,
  originalValue: PropTypes.string,
});

export const editCustomerDetailFormFieldsType = PropTypes.arrayOf(
  PropTypes.oneOfType([
    editCustomerDetailFormFieldType,
    PropTypes.arrayOf(editCustomerDetailFormFieldType),
  ])
);

function EditCustomerDetailFormFields({ fields, defaultOnChange, formValues, setFormValues }) {
  const dict = useLocalizedSentenceDict();

  return (
    <>
      {fields.map((field) =>
        Array.isArray(field) ? (
          <div
            className="edit-customer-detail-form__content-same-row"
            key={field.map((f) => f.key).join()}
          >
            <EditCustomerDetailFormFields
              fields={field}
              defaultOnChange={defaultOnChange}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </div>
        ) : (
          <label htmlFor={field.key} className="subtext" key={field.key}>
            {dict.get(field.name)}
            <input
              id={field.key}
              value={formValues[field.key] || ""}
              onChange={(e) =>
                (field.handleOnChange || defaultOnChange)(e, field, formValues, setFormValues)
              }
              type={field.inputType || "text"}
              autoComplete={field.autoComplete || "on"}
            />
          </label>
        )
      )}
    </>
  );
}

EditCustomerDetailFormFields.propTypes = {
  fields: editCustomerDetailFormFieldsType,
  defaultOnChange: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.string),
  setFormValues: PropTypes.func,
};

function EditCustomerDetailForm({
  title,
  fields,
  onSubmitCustomerDetail,
  errorMessage,
  isLoading,
}) {
  const dict = useLocalizedSentenceDict();
  const [formValues, setFormValues] = useState({});

  // Refresh form if fields change
  useEffect(() => {
    setFormValues(
      fields.reduce(
        (acc, field) =>
          Array.isArray(field)
            ? {
                ...acc,
                ...field.reduce(
                  (accInlineField, inlineField) => ({
                    ...accInlineField,
                    [inlineField.key]: inlineField.originalValue || "",
                  }),
                  {}
                ),
              }
            : { ...acc, [field.key]: field.originalValue || "" },
        {}
      )
    );
  }, [fields]);

  const defaultOnChange = (e, field) =>
    setFormValues({ ...formValues, [field.key]: e.target.value });

  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubmitCustomerDetail(formValues);
  };

  return (
    <form className="edit-customer-detail-form" onSubmit={onSubmitForm}>
      <div className="edit-customer-detail-form__content">
        <h2 className="h4 h4--bold">{dict.get(title)}</h2>

        <EditCustomerDetailFormFields
          fields={fields}
          defaultOnChange={defaultOnChange}
          formValues={formValues}
          setFormValues={setFormValues}
        />

        <ALButton
          className="submit"
          type="submit"
          variant="tertiary"
          size="medium"
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? <ALLoading fill="white" /> : dict.get("Confirm Change")}
        </ALButton>

        {errorMessage && <div className="error">{errorMessage}</div>}
      </div>
    </form>
  );
}

EditCustomerDetailForm.propTypes = {
  title: PropTypes.string,
  fields: editCustomerDetailFormFieldsType,
  onSubmitCustomerDetail: PropTypes.func,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default EditCustomerDetailForm;
