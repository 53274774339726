import { useState, useContext } from "react";
import loadable from "@loadable/component";

import AddressesContext from "../../../context/AddressesProvider";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import IconAdd from "../../icons/svgs/add.svg";
import AddressCard from "./addressBook/AddressCard";
import ADDRESS_POPUP_TYPE from "../../../constants/AddressPopupType";

import * as Styles from "./styles.module.scss";

const AddressPopup = loadable(() =>
  import(/* webpackPrefetch: true */ "./addressBook/AddressPopup")
);

function AddressBook() {
  const dict = useLocalizedSentenceDict();
  const { defaultAddress, addresses } = useContext(AddressesContext);
  const [isAddressPopupOpen, setIsAddressPopupOpen] = useState(false);

  const closePopup = () => {
    setIsAddressPopupOpen(false);
  };

  return (
    <div className={Styles.addressContainer}>
      <div className={Styles.addressContainer__title}>
        <h2 className="h2 h2--bold h2--uc mt-0 mb-0">{dict.get("My Address Book")}</h2>
        <button
          type="button"
          className={Styles.addAddress}
          onClick={() => setIsAddressPopupOpen(true)}
        >
          <IconAdd width="30" fill="#2d2927" />
        </button>
      </div>
      <div className={Styles.addressContainer__address}>
        {defaultAddress && (
          <AddressCard
            key={`address_customer_${defaultAddress.id}`}
            address={defaultAddress}
            isDefaultAddress
            idx={1}
          />
        )}
        {addresses?.edges
          ?.filter((a) => a.node.id !== defaultAddress?.id)
          ?.map((a, i) => (
            <AddressCard key={`address_customer_${a.node.id}`} address={a.node} idx={i + 2} />
          ))}
      </div>
      <AddressPopup
        type={ADDRESS_POPUP_TYPE.ADD}
        isOpen={isAddressPopupOpen}
        closePopup={closePopup}
      />
    </div>
  );
}

export default AddressBook;
