import PropTypes from "prop-types";
import { useCallback, useReducer } from "react";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import EditCustomerDetailPopup from "./customerDetail/EditCustomerDetailPopup";
import * as Styles from "./styles.module.scss";

function CustomerDetail({
  accountInfo: { firstName, lastName, email },
  updateCustomerDetail,
  error,
  setError,
  isLoading,
}) {
  const dict = useLocalizedSentenceDict();
  const setPopupField = (state, action) => {
    setError(null);
    switch (action) {
      case "name":
        return {
          title: "Edit Name",
          fields: [
            [
              {
                key: "firstName",
                name: "First Name",
                originalValue: firstName,
                inputAutocomplete: "given-name",
                inputType: "text",
              },
              {
                key: "lastName",
                name: "Last Name",
                originalValue: lastName,
                inputAutocomplete: "family-name",
                inputType: "text",
              },
            ],
          ],
        };
      case "password":
        return {
          title: "Edit Password",
          fields: [
            {
              key: "password",
              name: "Password",
              originalValue: "",
              inputAutocomplete: "new-password",
              inputType: "password",
            },
            {
              key: "confirmPassword",
              name: "Password Confirmation",
              originalValue: "",
              inputAutocomplete: "new-password",
              inputType: "password",
            },
          ],
        };
      case "email":
        return {
          title: "Edit Email",
          fields: [
            {
              key: "email",
              name: "Email",
              originalValue: email,
              inputAutocomplete: "email",
              inputType: "email",
            },
          ],
        };
      default:
        return null;
    }
  };
  const [popup, setPopup] = useReducer(setPopupField, null);

  const isEditCustomerDetailPopupOpen = !!popup;

  const closePopup = useCallback(() => {
    setPopup(null);
  }, [setPopup]);

  const onSubmitCustomerDetail = useCallback(
    (formData) => {
      const customerUpdateInput = { ...formData };
      if (formData.confirmPassword) {
        if (formData.password && formData.password !== formData.confirmPassword) {
          setError("Password and Confirm Password does not match");
          return;
        }
        delete customerUpdateInput.confirmPassword;
      }
      updateCustomerDetail(customerUpdateInput).then((isSuccess) => isSuccess && closePopup());
    },
    [setError, updateCustomerDetail, closePopup]
  );

  return (
    <div className={Styles.accountDetails}>
      <h2 className="h2 h2--bold h2--uc mt-0">{dict.get("My Details")}</h2>
      <div className={Styles.accountDetails__card}>
        {firstName && lastName && (
          <div className={Styles.accountDetails__detail}>
            <span>
              <p className={Styles.accountDetails__label}>{dict.get("Name")}</p>
              <p>
                {firstName} {lastName}
              </p>
            </span>
            <button
              type="button"
              aria-label="Edit"
              className={Styles.accountDetails__action}
              onClick={() => setPopup("name")}
            >
              Edit
            </button>
          </div>
        )}

        {email && (
          <div className={Styles.accountDetails__detail}>
            <span>
              <p className={Styles.accountDetails__label}>{dict.get("Email")}</p>
              <p className="">{email}</p>
            </span>
            <button
              type="button"
              aria-label="Edit"
              className={Styles.accountDetails__action}
              onClick={() => setPopup("email")}
            >
              Edit
            </button>
          </div>
        )}

        <div className={Styles.accountDetails__detail}>
          <span>
            <p className={Styles.accountDetails__label}>{dict.get("Password")}</p>
            <p>**************</p>
          </span>
          <button
            type="button"
            aria-label="Edit"
            className={Styles.accountDetails__action}
            onClick={() => setPopup("password")}
          >
            Edit
          </button>
        </div>
      </div>
      <EditCustomerDetailPopup
        title={popup?.title}
        fields={popup?.fields}
        isOpen={isEditCustomerDetailPopupOpen}
        closePopup={closePopup}
        onSubmitCustomerDetail={onSubmitCustomerDetail}
        errorMessage={error}
        isLoading={isLoading}
      />
    </div>
  );
}

CustomerDetail.propTypes = {
  accountInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  updateCustomerDetail: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CustomerDetail;
