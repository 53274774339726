import { createContext } from "react";

export const defaultAddressesContext = {
  defaultAddress: null,
  addresses: [],
  addAddress: () => {},
  deleteAddress: () => {},
  editAddress: () => {},
  error: null,
  setError: () => {},
  isLoading: false,
};

const AddressesContext = createContext(defaultAddressesContext);

export default AddressesContext;
