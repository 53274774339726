import { useContext } from "react";
import PropTypes from "prop-types";

import AddressesContext from "../../../../../context/AddressesProvider";
import { useLocalizedSentenceDict } from "../../../../../hooks/useSentenceDict";
import { Popup } from "../../../../Popup";
import IconClose from "../../../../icons/svgs/close.svg";
import { ALLoading, ALButton } from "../../../../ALComponents";

import "./deleteConfirmationPopup/styles.scss";

function DeleteConfirmationPopup({ isOpen, onAction = () => {}, onClose }) {
  const dict = useLocalizedSentenceDict();
  const { error, isLoading } = useContext(AddressesContext);

  return (
    <Popup
      open={isOpen}
      modal
      closeOnDocumentClick
      className="delete-address-popup"
      onClose={onClose}
    >
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose height="30" width="30" />
      </button>
      <h1 className="confirmation-title h3">{dict.get("Are you sure you want to delete?")}</h1>
      <ALButton
        className="confirmation-btn"
        type="button"
        size="medium"
        variant="tertiary"
        disabled={isLoading}
        onClick={onAction}
      >
        {isLoading ? <ALLoading fill="white" /> : dict.get("Yes, Delete address")}
      </ALButton>
      {error && <div className="error">{error}</div>}
    </Popup>
  );
}

DeleteConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default DeleteConfirmationPopup;
