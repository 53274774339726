import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import ALRewardsHeader from "../ALRewardsHeader";
import ALRewardsNavigation from "../ALRewardsNavigation";

function HeaderContainer() {
  return useResponsiveWithHydrationFix(
    <ALRewardsNavigation />,
    <>
      <ALRewardsHeader />
      <ALRewardsNavigation />
    </>
  );
}

export default HeaderContainer;
