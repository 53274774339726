import { useEffect } from "react";
import { navigate } from "gatsby";

import { useAuth } from "../../context/AuthProvider";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { isBrowser } from "../../context/helpers";

import SEO from "../../components/seo";
import AccountDetails from "../../components/accountPage/AccountDetails";
import HeaderContainer from "../../components/alRewards/HeaderContainer";
import { ALLoading } from "../../components/ALComponents";

import "./account.scss";

function IndexAccount() {
  const { accountDetails, isLoading } = useAuth();
  const dict = useLocalizedSentenceDict();

  useEffect(() => {
    if (!accountDetails && !isLoading) {
      navigate("/account/login");
    } else if (isBrowser && accountDetails && !isLoading) {
      // identify exponea
      window.exponea.identify(
        {
          email_id: accountDetails.email,
        },
        {
          email: accountDetails.email,
        }
      );
    }
  }, [accountDetails]);

  useEffect(() => {
    // Redirect after 5s in case customer land in this page
    const timer = setTimeout(() => {
      if (!accountDetails) {
        navigate("/");
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [accountDetails]);

  return (
    <>
      <SEO title="Account | Ana Luisa Jewelry" />
      {accountDetails ? (
        <>
          <HeaderContainer />
          <div className="account_details">
            <AccountDetails accountDetails={accountDetails} />
          </div>
        </>
      ) : (
        <div className="account_loading">
          <ALLoading />
          <p className="h3">{dict.get("Loading your profile")}...</p>
        </div>
      )}
    </>
  );
}

export default withALErrorBoundary({
  name: "IndexAccount",
  priority: "P2",
})(IndexAccount);
