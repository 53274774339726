import { PropTypes } from "prop-types";

import { Popup } from "../../../Popup";
import EditCustomerDetailForm, {
  editCustomerDetailFormFieldsType,
} from "./EditCustomerDetailPopup/EditCustomerDetailForm";

import CloseIcon from "../../../icons/svgs/close.svg";

import "./EditCustomerDetailPopup/EditCustomerDetailPopup.scss";

function EditCustomerDetailPopup({
  title = "",
  fields = [],
  onSubmitCustomerDetail,
  errorMessage,
  isLoading,
  isOpen,
  closePopup,
}) {
  return (
    <Popup
      className="edit_customer-detail"
      open={isOpen}
      modal
      closeOnDocumentClick
      onClose={closePopup}
    >
      <button type="button" className="close-icon" onClick={closePopup}>
        <CloseIcon height="30px" width="30px" />
      </button>
      <EditCustomerDetailForm
        title={title}
        fields={fields}
        onSubmitCustomerDetail={onSubmitCustomerDetail}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </Popup>
  );
}

EditCustomerDetailPopup.propTypes = {
  title: PropTypes.string,
  fields: editCustomerDetailFormFieldsType,
  onSubmitCustomerDetail: PropTypes.func,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default EditCustomerDetailPopup;
