import PropTypes from "prop-types";

import Header from "./alRewardsHeader/Header";

function ALRewardsHeader({ type = "" }) {
  return (
    <div>
      <Header type={type} />
    </div>
  );
}

ALRewardsHeader.propTypes = {
  type: PropTypes.string,
};

export default ALRewardsHeader;
