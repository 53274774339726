import axios from "axios";
import { useState, useMemo, useCallback } from "react";

import AddressesContext from "../../context/AddressesProvider";
import {
  withALErrorBoundary,
  alReportError,
  useALError,
} from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { getCookie } from "../../context/helpers";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";
import { useAuth } from "../../context/AuthProvider";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { ALButton } from "../ALComponents";
import AddressBook from "./accountDetails/AddressBook";
import CustomerDetail from "./accountDetails/CustomerDetail";

import * as Styles from "./accountDetails/styles.module.scss";

function AccountDetails() {
  const { sendReport } = useALError();

  const { accountDetails, setAccountDetails, logout } = useAuth();
  const countryCode = useCurrentCountryCode();
  const store = getShopifyStoreByCountry(countryCode);
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();

  const {
    firstName = "",
    lastName = "",
    email = "",
    defaultAddress = {},
    addresses = [],
  } = accountDetails || {};

  // Error and isLoading states are shared between
  // add, edit and delete addresses, so make sure to clear states
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const updateCustomerDetail = useCallback(
    async (data) => {
      const accessToken = getCookie(`${store.name}-accessToken`);
      let isSuccess = false;
      try {
        setIsLoading(true);
        const res = await axios.put(
          `/.netlify/functions/account`,
          { accessToken, customerUpdateInput: data },
          {
            params: { countryCode },
          }
        );
        if (res.status === 200) {
          setAccountDetails(res.data.customer);
          setError(null);
          isSuccess = true;
        }
      } catch (e) {
        const res = e.response;
        setError(`Failed to update: ${res ? res.data : JSON.stringify(e)}`);
        sendReport(e, { name: "AccountDetails", priority: "P3" });
        if (res.status === 401) {
          logout();
        } else {
          console.error(res);
          alReportError(e);
        }
      } finally {
        setIsLoading(false);
      }
      return isSuccess;
    },
    [countryCode, setAccountDetails, store, logout]
  );

  const editAddress = useCallback(
    async (data) => {
      let isSuccess = false;
      setIsLoading(true);
      const accessToken = getCookie(`${store.name}-accessToken`);

      const body = {
        ...data,
        accessToken,
        countryCode,
      };

      try {
        const res = await axios.post(`/.netlify/functions/update-address`, body);
        if (res.status === 200) {
          setAccountDetails(res.data.customer);
          setError(null);
          isSuccess = true;
        }
      } catch (e) {
        const res = e.response;
        setError(`Failed to update address: ${res ? res.data : JSON.stringify(e)}`);
        sendReport(e, { name: "AccountDetails", priority: "P3" });
        if (res.status === 401) {
          logout();
        } else {
          console.error(res);
          alReportError(e);
        }
      } finally {
        setIsLoading(false);
      }
      return isSuccess;
    },
    [countryCode, setAccountDetails, store, logout]
  );

  const addAddress = useCallback(
    async (data) => {
      let isSuccess = false;
      setIsLoading(true);
      const accessToken = getCookie(`${store.name}-accessToken`);

      const body = {
        ...data,
        accessToken,
        countryCode,
      };

      try {
        const res = await axios.post(`/.netlify/functions/create-address`, body);
        if (res.status === 200) {
          setAccountDetails(res.data.customer);
          setError(null);
          isSuccess = true;
        }
      } catch (e) {
        const res = e.response;
        setError(`Failed to add address: ${res ? res.data : JSON.stringify(e)}`);
        sendReport(e, { name: "AccountDetails", priority: "P3" });
        if (res.status === 401) {
          logout();
        } else {
          console.error(res);
          alReportError(e);
        }
      } finally {
        setIsLoading(false);
      }
      return isSuccess;
    },
    [countryCode, setAccountDetails, store, logout]
  );

  const deleteAddress = useCallback(
    async (id) => {
      let isSuccess = false;
      setIsLoading(true);
      const accessToken = getCookie(`${store.name}-accessToken`);
      try {
        const res = await axios.get(
          `/.netlify/functions/delete-address?countryCode=${countryCode}&id=${id}&accessToken=${accessToken}`
        );
        if (res.status === 200) {
          setAccountDetails(res.data.customer);
          setError(null);
          isSuccess = true;
        } else {
          setError(`${res.status} ${res.data}`);
        }
      } catch (e) {
        const res = e.response;
        setError(`Failed to delete address: ${res ? res.data : JSON.stringify(e)}`);
        sendReport(e, { name: "AccountDetails", priority: "P3" });
        if (res.status === 401) {
          logout();
        } else {
          console.error(res);
          alReportError(e);
        }
      } finally {
        setIsLoading(false);
      }
      return isSuccess;
    },
    [countryCode, setAccountDetails, store, logout]
  );

  const value = useMemo(
    () => ({
      defaultAddress,
      addresses,
      addAddress,
      deleteAddress,
      editAddress,
      error,
      setError,
      isLoading,
    }),
    [defaultAddress, addresses, addAddress, deleteAddress, editAddress, error, isLoading]
  );

  return (
    <div className={Styles.accountDetailsContainer}>
      <CustomerDetail
        accountInfo={{ firstName, lastName, email }}
        updateCustomerDetail={updateCustomerDetail}
        error={error}
        setError={setError}
        isLoading={isLoading}
      />
      <AddressesContext.Provider value={value}>
        <AddressBook />
      </AddressesContext.Provider>
      {isMobile && (
        <ALButton variant="secondary" onClick={logout}>
          {dict.get("LOG OUT")}
        </ALButton>
      )}
    </div>
  );
}

export default withALErrorBoundary({
  name: "AccountDetails",
  priority: "P3",
})(AccountDetails);
