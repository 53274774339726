import { useState, useContext } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

import AddressesContext from "../../../../context/AddressesProvider";
import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";
import DeleteConfirmationPopup from "./addressCard/DeleteConfirmationPopup";
import ADDRESS_POPUP_TYPE from "../../../../constants/AddressPopupType";

import * as Styles from "../styles.module.scss";

const AddressPopup = loadable(() => import(/* webpackPrefetch: true */ "./AddressPopup"));

function AddressCard({ address, idx, isDefaultAddress }) {
  const dict = useLocalizedSentenceDict();
  const { deleteAddress, setError } = useContext(AddressesContext);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isAddressPopupOpen, setIsAddressPopupOpen] = useState(false);

  const closeDeletePopup = () => {
    setError(null);
    setShowConfirmationPopup(false);
  };

  const closeAddressPopup = () => {
    setError(null);
    setIsAddressPopupOpen(false);
  };

  const handleDeleteAddress = (id) => {
    deleteAddress(id).then((isSuccessful) => {
      if (isSuccessful) {
        closeDeletePopup();
        setShowConfirmationPopup(false);
      }
    });
  };

  return (
    <>
      <div className={Styles.addressCard} data-testid="address-card">
        <div className={Styles.addressCard__content}>
          <h4 className="text text--bold mb-0 mt-0">
            {dict.get("Address")} {idx}
          </h4>
          <div className={Styles.addressCard__address}>
            <p>{`${address?.firstName || ""} ${address?.lastName || ""}`}</p>
            {address?.address1 && <p>{address.address1}</p>}
            {address?.address2 && <p>{address.address2}</p>}
            <p>
              {address?.city && address.city}, {address?.provinceCode && address.provinceCode}{" "}
              {address?.zip && address.zip}
            </p>
            {address?.country && <p>{address.country}</p>}
          </div>
          {isDefaultAddress && <p className={Styles.addressCard__default}>Default</p>}
        </div>
        <div className={Styles.addressCard__actions}>
          <button type="button" aria-label="Edit" onClick={() => setIsAddressPopupOpen(true)}>
            Edit
          </button>
          <button type="button" aria-label="Delete" onClick={() => setShowConfirmationPopup(true)}>
            Delete
          </button>
        </div>
      </div>
      <AddressPopup
        type={ADDRESS_POPUP_TYPE.EDIT}
        isOpen={isAddressPopupOpen}
        closePopup={closeAddressPopup}
        address={address}
      />
      <DeleteConfirmationPopup
        isOpen={showConfirmationPopup}
        onAction={() => handleDeleteAddress(address.id)}
        onClose={closeDeletePopup}
      />
    </>
  );
}

AddressCard.propTypes = {
  address: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    zip: PropTypes.string,
    id: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    phone: PropTypes.string,
  }),
  idx: PropTypes.number,
  isDefaultAddress: PropTypes.bool,
};
export default AddressCard;
