import PropTypes from "prop-types";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useAuth } from "../../context/AuthProvider";

import clubAlSignedInHeroDesktop from "../../images/rewards/club-al-hero.jpg";
import clubAlSignedInHeroMobile from "../../images/rewards/club-al-hero-mobile.jpg";

import luxeAlHeroDesktop from "../../images/rewards/luxe-al-hero.png";
import luxeAlHeroMobile from "../../images/rewards/luxe-al-hero-mobile.png";

import * as Styles from "./header/styles.module.scss";

function Header({ type }) {
  const { accountDetails } = useAuth();
  const { isMobile } = useDeviceDetect();

  return (
    <section
      className={`${Styles.header} ${type === "luxe" ? Styles.headerLuxe : Styles.headerDefault}`}
    >
      <div className={Styles.headerContent}>
        <div className={Styles.headerContentUpper}>
          <div>
            <h2 className="mt-0 mb-0 h2 h2--bold">
              Hi, {accountDetails?.firstName} {accountDetails?.lastName}
            </h2>
          </div>
        </div>
      </div>

      {!isMobile ? (
        type === "luxe" ? (
          <img src={luxeAlHeroDesktop} className={Styles.headerHeroDesktop} alt="Club Ana Luisa" />
        ) : (
          <img
            src={clubAlSignedInHeroDesktop}
            className={Styles.headerHeroDesktop}
            alt="Club Ana Luisa"
          />
        )
      ) : type === "luxe" ? (
        <img src={luxeAlHeroMobile} alt="Club Ana Luisa" />
      ) : (
        <img src={clubAlSignedInHeroMobile} alt="Club Ana Luisa" />
      )}
    </section>
  );
}

Header.propTypes = {
  type: PropTypes.string,
};

export default Header;
